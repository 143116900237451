import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import { selectCurrentIntake, selectCustomer, selectNextStepPath } from 'app/selectors/customer';
import { push } from 'connected-react-router/immutable';
import { apiRequestUserCommand } from 'app/actions/customer';
import { Formik } from 'formik';
import BackNextFooter from 'app/components/customer/steps/BackNextFooter';
import PageHeader from 'app/components/common/PageHeader';
import { selectCustomerProduct } from 'app/selectors/customer';
import createCheckoutHelper from 'app/helpers/createCheckoutHelper';

const SubProductSelectionStep = () => {
  const intake = useAppSelector(selectCurrentIntake);
  const dispatch = useAppDispatch();
  const customer = useAppSelector(selectCustomer);
  const productName = intake.get('product_name');
  const product = useAppSelector((state) => selectCustomerProduct(state, productName));

  const checkoutHelper = createCheckoutHelper(intake, customer, product, true);
  const nextStep = useAppSelector(selectNextStepPath);

  const onSuccessActionCreator = (_, _stateAfterSuccess) => push(nextStep);

  const initialValues = checkoutHelper.productSpecificInitialValues();
  const onSubmit = (params: any) => {
    const action = apiRequestUserCommand({
      cmdType: checkoutHelper.updateSubProductCommandName,
      params: { ...params, intake: intake.get('name') },
      context: { onSuccessActionCreator },
    });
    dispatch(action);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <div className="centered updated-design payments mb24">
          <PageHeader title="Choose Your Protocol" className="treatment_plan__header mb24 -edged" />
          <div className="content">
            {checkoutHelper.renderProductStep(true)}
            <BackNextFooter
              noBack={true}
              onNext={handleSubmit}
              testId="submit-product-selection"
              horizontalPadding={false}
            />
          </div>
        </div>
      )}
    </Formik>
  );
};

export default SubProductSelectionStep;
