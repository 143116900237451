import { CustomerUserImm, LoverIntake, LoverProduct } from 'app/types/admin/customerUser';
import { ImmutableMap } from 'app/types/admin';
import BaseCheckoutHelper from 'app/components/customer/steps/Payment/Generic/BaseCheckoutHelper';
import { AvailableProducts } from 'app/constants/Products';
import LoverPriceCalculator from 'app/helpers/productSpecificPriceCalculators/loverPriceCalculator';
import PriceCalculator from 'app/helpers/priceCalculator';

class LoverCheckoutHelper extends BaseCheckoutHelper {
  intake: ImmutableMap<LoverIntake>;
  product: ImmutableMap<LoverProduct>;

  constructor(
    intake: ImmutableMap<LoverIntake>,
    customer: CustomerUserImm,
    product: ImmutableMap<LoverProduct>,
    isOnboarding: boolean,
  ) {
    super(customer, intake, isOnboarding);

    this.product = product;
    this.intake = intake;
  }

  currentProductName = () => AvailableProducts.Lover;

  productSpecificInitialValues = () => {
    return {
      daily_strength: this.subProductChoice().get('daily_strength'),
      number_of_doses: this.subProductChoice().get('number_of_doses'),
      strength_variant: this.subProductChoice().get('strength_variant'),
    };
  };

  subProductChoice = () => this.product.get('opt_in_choice')?.get('sub_product_choice');

  otherActiveSubscription = () => {
    const activeProductNames = this.customer.get('active_products_names');
    return (
      activeProductNames.count() > 0 &&
      (activeProductNames.count() > 1 || activeProductNames[0] != AvailableProducts.Lover)
    );
  };

  priceCalculator = (values: any, _subscription_paid: boolean = false, _lab_paid: boolean = false) => {
    const loverPriceCalculator = new LoverPriceCalculator(
      values.include_supplement,
      this.supplementsOnAnotherIntake(),
      this.intake.get('name'),
      values.daily_strength,
      values.number_of_doses,
      this.otherActiveSubscription(),
    );

    return new PriceCalculator(loverPriceCalculator, this.product.get('discounts'));
  };
}

export default LoverCheckoutHelper;
