import React from 'react';
import { push } from 'connected-react-router/immutable';
import * as actions from 'app/actions/customer';
import * as selectors from 'app/selectors/customer';
import BackNextFooter from 'app/components/customer/steps/BackNextFooter';
import './css/PreQualified.scss';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import { selectFurthestProductStepPath, selectCurrentIntakeProduct } from 'app/selectors/customer';

const PreQualified = ({ step }) => {
  const dispatch = useAppDispatch();
  const intake = useAppSelector(selectors.selectCurrentIntake);
  const productName = useAppSelector(selectCurrentIntakeProduct).toLowerCase();

  const onSuccessActionCreator = (_, stateAfterSuccess) =>
    push(selectFurthestProductStepPath({ customer: stateAfterSuccess }, productName));
  const submitPreQualified = () => {
    const action = actions.apiRequestUserCommand({
      cmdType: 'answer_questions',
      params: { [step]: true, intake: intake.get('name') },
      context: { onSuccessActionCreator },
    });
    dispatch(action);
  };

  return (
    <div className="pre-qualified-step">
      <div className="pre-qualified-step__wrap">
        <div className="pre-qualified-step-top">
          <h1 className="pre-qualified-step-top__title">You’re pre-qualified!</h1>
        </div>

        <div className="pre-qualified-step-block">
          <div className="pre-qualified-step-block__list block-list">
            <div className="block-list__item mt24">
              <p className="list-item__text">You&apos;ve been pre-qualified based on your responses.</p>
              <p className="list-item__text">
                The next step is to confirm you lab selection, review your order details and complete checkout.
              </p>
              <p className="list-item__text">
                After checkout your baseline lab work will be sent to your Maximus doctor for final approval.
              </p>
              <p className="list-item__text">
                No need to sweat it. Most men are approved for treatment, but rest assured that you will be refunded if
                you aren’t.
              </p>
            </div>
          </div>
        </div>

        <div className="pre-qualified-step-choose-plan">
          <BackNextFooter
            noBack={true}
            onNext={submitPreQualified}
            nextText="Continue"
            testId="submit-pre-qualified-step"
            className="pre-qualified-step-choose-plan__link default-button primary-button"
            horizontalPadding={false}
          />
        </div>
      </div>
    </div>
  );
};

export default PreQualified;
