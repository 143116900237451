import React, { useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import * as routerSelectors from 'app/selectors/router';
import { push } from 'connected-react-router/immutable';
import { hotjarIdentity, segmentAlias } from 'app/utils';
import * as Routes from 'app/constants/Routes';
import { guestRegistrationTermsSubmitted, NOOP } from 'app/actions/customer';
import { AvailableProducts } from 'app/constants/Products';
import 'app/css/NewDesignSystem.scss';
import AccountDataFillStep from 'app/components/customer/Registration/LabsRegistration/AccountDataFillStep';
import EnterEmailStep from 'app/components/customer/Registration/LabsRegistration/EnterEmailStep';

const mapStateToProps = (state) => {
  const feedbackParam = routerSelectors.selectRouterQuery(state).get('registration_feedback');
  const testMode = routerSelectors.selectRouterQuery(state).get('test_mode');

  return {
    feedbackParam,
    initialValues: {
      settings: {
        product: AvailableProducts.King,
        test_mode: testMode,
      },
      step0: {
        email: '',
        pc_opt_in: true,
        mso_opt_in: true,
      },
      step1: {
        first_name: '',
        last_name: '',
        sex: 'm',
        date_of_birth: '',
      },
    },
  };
};

const LabsRegistration = ({ initialValues, dispatch }) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [allValues, setAllValues] = useState(initialValues);
  const [currentStep, setCurrentStep] = useState(0);
  const stepValue = allValues[`step${currentStep}`];

  const onSubmitNextStep = useCallback(
    (values) => {
      setAllValues((allValues) => ({
        ...allValues,
        [`step${currentStep}`]: values,
      }));
      setCurrentStep(currentStep + 1);
    },
    [currentStep],
  );

  const onSubmitSendData = useCallback(
    async (values, form) => {
      const email = allValues.step0.email.trim();
      const action = {
        params: {
          ...Object.values(allValues as Record<string, Record<string, any>>).reduce(
            (reduced, val) => ({ ...reduced, ...val }),
            {},
          ),
          ...values,
          over_18: true,
          only_lab: true,
          email,
          href: window.location.href,
          referrer: window.referrer,
        },
        context: {
          onSuccessActionCreator: ({ json: { registration_feedback, user_id } }) => {
            if (user_id) {
              hotjarIdentity(user_id);
              segmentAlias(user_id);
            }

            if (registration_feedback) {
              return push(`${Routes.Registration}?registration_feedback=${registration_feedback}`);
            }

            window.location.href = Routes.LabCheckout;
          },
          onFailureActionCreator: ({ json: { errors } }) => {
            form.setErrors(errors);

            return { type: NOOP };
          },
        },
      };
      Cookies.set('email', email);
      await dispatch(guestRegistrationTermsSubmitted(action));
    },
    [dispatch, allValues],
  );

  const content = useMemo(
    () => (
      <>
        {currentStep === 0 && <EnterEmailStep initialValues={stepValue} onSubmitNextStep={onSubmitNextStep} />}
        {currentStep === 1 && <AccountDataFillStep initialValues={stepValue} onSubmit={onSubmitSendData} />}
      </>
    ),
    [currentStep, onSubmitNextStep, onSubmitSendData, stepValue],
  );

  return <div className="new-design-system content">{content}</div>;
};

export default connect(mapStateToProps)(LabsRegistration);
