import React from 'react';
import LabImage from 'images/LabImage.png';
import 'app/css/NewDesignSystem.scss';
import Button from '@setproduct-ui/core/Button';
import { push } from 'connected-react-router/immutable';
import * as Routes from 'app/constants/Routes';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'app/helpers/hooks';
import { selectCustomer } from 'app/selectors/customer';

const LabCheckoutSuccessPage = () => {
  const dispatch = useDispatch();
  const customer = useAppSelector(selectCustomer);
  const lab_orders = customer?.get('lab_orders')?.toArray();
  const lab_order = lab_orders?.find((order) => order.get('order_status') === 'pending');

  return (
    <div className="centered new-design-system">
      <h5 className="title mt32 mb0">THANK YOU</h5>
      <h3 className="title">Your Order #{lab_order?.get('order_id')}</h3>
      <div className="new-design-system content">
        <div className="content">
          <div className="product-image">
            <img src={LabImage} />
          </div>
          <h5 className="title mb24 mt24">WHAT TO EXPECT</h5>
          <div>
            <p className="bold">Ships to You Be</p>
            on the look out for an email letting you know that your Test Kit has shipped. You can expect to see your kit
            in 2-3 business days.
            <p className="bold mt24">Complete Test</p>
            Follow the instructions included with the kit to complete your blood draw and package it to send to the lab.
            <p className="bold mt24">Ship to the Lab</p>
            Once you ship the kit to the lab, please allow up to 2 business days for results to be posted.
            <p className="bold mt24">View Results</p>
            You can expect an email from us letting you know that your results are ready to view.
          </div>
          <Button
            data-testid="modal-biomarkers-got-it"
            text="View Dashboard"
            className="default-button mt24"
            onClick={() => dispatch(push(Routes.Dashboard))}
          />
        </div>
      </div>
    </div>
  );
};

export default LabCheckoutSuccessPage;
