import React from 'react';
import { useExperiment } from 'app/utils/useExperiment';
import './css/DiscountBanner.scss';

const DiscountBanner = () => {
  const isVariant =
    useExperiment('testosterone_discount_control', 'testosterone_discount_variation_1') ===
    'testosterone_discount_variation_1';

  if (!isVariant) return null;

  return (
    <div className="discount-banner-exp">
      <p>
        Promo Code <span>FIRST100</span> has been applied to give you $100 Off today&apos;s purchase
      </p>
    </div>
  );
};

export default DiscountBanner;
