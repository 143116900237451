import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import Button from 'app/components/common/formik/Button';
import LabImage from 'images/LabImage.png';
import SetProductDialog from '@setproduct-ui/core/Dialog';
import Divider from '@setproduct-ui/core/Divider';
import { CustomDialogCloseIcon } from 'app/components/common/NewDesignSystem/CustomDialogCloseIcon';
import Input from 'app/components/common/formik/Input';
import { Link } from 'react-router-dom';
import * as Routes from 'app/constants/Routes';
import FormField from 'app/components/common/NewDesignSystem/FormField';
import { getCSRF } from 'app/api';
import axios from 'axios';
import { RegistrationSchemaLabEmail } from 'app/helpers/validators';

const ModalBodyBiomarkers = ({ setShowModal }) => (
  <div className="modal-body new-design-system">
    <p className="description">
      We analyze these key biomarkers to give you a complete picture of your testosterone health:
    </p>
    <ul>
      <li>
        <b className="bold">Free Testosterone</b>: Active testosterone your body can actually use.
      </li>
      <li>
        <b className="bold">Total Testosterone</b>: Total amount of testosterone in your body, both active and inactive.
      </li>
      <li>
        <b className="bold">SHBG (Sex Hormone-Binding Globulin)</b>: Protein that binds to testosterone, affecting how
        much is available for your body to use.
      </li>
      <li>
        <b className="bold">E2 (estradiol)</b>: Form of estrogen that helps balance testosterone and overall hormonal
        health.
      </li>
      <li>
        <b className="bold">LH (luteinizing hormone)</b>: Regulates testosterone production.
      </li>
      <li>
        <b className="bold">FSH (Follicle-Stimulating Hormone)</b>: Important for sperm production and reproductive
        health.
      </li>
      <li>
        <b className="bold">TPSA</b>: Measures prostate-specific antigen to monitor prostate health.
      </li>
      <li>
        <b className="bold">ALT</b>: Liver enzyme that helps ensure your liver is functioning properly.
      </li>
      <li>
        <b className="bold">Vitamin D</b>: Plays a role in testosterone production and overall energy levels.
      </li>
    </ul>
    <Button
      style="primary"
      type="button"
      data-testid="modal-biomarkers-got-it"
      text="Got It"
      className="button-next"
      onClick={() => setShowModal(false)}
    />
  </div>
);

const EnterEmailStep = ({ initialValues, onSubmitNextStep }) => {
  const msoTermsOfUseLink = '/terms-of-use';
  const msoPrivacyPolicyLink = '/privacy-policy';
  const [showModal, setShowModal] = useState(false);
  const [emailExist, setEmailExist] = useState(false);

  const onSubmit = async ({ ...values }) => {
    await axios
      .post(
        '/api/guests/validate_email_uniqueness',
        {
          email: values.email,
        },
        { headers: { 'X-CSRF-Token': getCSRF() } },
      )
      .then((response) => {
        setEmailExist(!response.data.unique);

        if (response.data.unique) {
          onSubmitNextStep(values);
        }
      });
  };

  const renderBiomarkersModal = () => (
    <SetProductDialog
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      title={<h3 className="title">Biomarkers We Test</h3>}
      text={<ModalBodyBiomarkers setShowModal={setShowModal} />}
      className="align-left"
      showCloseModalIcon={false}
      icon={<CustomDialogCloseIcon onClose={() => setShowModal(false)} />}
    />
  );

  return (
    <Formik
      initialValues={{
        ...initialValues,
      }}
      onSubmit={onSubmit}
      validationSchema={RegistrationSchemaLabEmail}
    >
      {({ isSubmitting, setFieldValue, isValid }) => (
        <Form>
          <h2 className="title">Testosterone Base Test</h2>
          <div className="product-image">
            <img src={LabImage} />
          </div>
          <p className="description mb8">
            Your <b className="bold">Testosterone Test Kit</b> arrives fast with free Priority Shipping, so you can
            check your key hormones quickly and accurately.
          </p>

          <a href="#" onClick={() => setShowModal(true)}>
            Learn about biomarkers we test for
          </a>

          <Divider className="mt24 mb24" />

          <p className="description">
            Please enter your email address which will allow you to access your results quickly.
          </p>

          <div className="inputs__item">
            <FormField label={'Email Address'}>
              <Input
                onChange={(e) => setFieldValue('email', e.target.value.trim())}
                id="email"
                name="email"
                placeholder="Enter Email Address"
                className="text-field"
                disabled={emailExist}
              />
              {emailExist && (
                <div className="exist">
                  This email already exists. Would you like to <Link to={Routes.Login}>Log in</Link>?
                </div>
              )}
            </FormField>
          </div>

          <Button
            disabled={isSubmitting || !isValid}
            style="primary"
            type="submit"
            data-testid="registration-step-0-submit"
            text="Next"
            className="button-next"
          />

          <p className="hints mt24">
            By clicking this button, I acknowledge that I have read, understand, and agree to the{' '}
            <a rel="noreferrer" href={msoTermsOfUseLink} target="_blank">
              Terms of Use
            </a>
            {' and '}
            <a rel="noreferrer" href={msoPrivacyPolicyLink} target="_blank">
              Privacy Policy
            </a>
            .
          </p>
          {renderBiomarkersModal()}
        </Form>
      )}
    </Formik>
  );
};

export default EnterEmailStep;
