import React from 'react';
import IconArrowUp from 'images/Icon-Arrow-Up.svg';
import HeadingWithIcon from 'app/components/common/HeadingWithIcon';
import Radio from 'app/components/common/Radio';
import { Form, Formik } from 'formik';
import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import { SecondaryButton } from 'app/components/common/Button';
import { push } from 'connected-react-router/immutable';
import * as Routes from 'app/constants/Routes';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomerFlags } from 'app/selectors/customer';
import KingPriceCalculator from 'app/helpers/productSpecificPriceCalculators/kingPriceCalculator';
import { KingV2SubProducts, LabKitTypes } from 'app/constants/Products';
const AnnualLabOrder = () => {
  const dispatch = useDispatch();
  const customerFlags = useSelector(selectCustomerFlags);
  const productPriceCalculator = new KingPriceCalculator(
    false,
    false,
    KingV2SubProducts.EncloPregnolone,
    1,
    false,
    'onboarding',
    null,
    false,
    LabKitTypes.AtHomeLabKit,
    true,
  );

  if (!customerFlags.includes('pending_annual_lab')) {
    return null;
  }
  return (
    <div className="progress_tracker__upnext__reason progress_tracker__upnext__annual_lab">
      <HeadingWithIcon icon={IconArrowUp}>
        <p className="heading">Order Annual Labs</p>
      </HeadingWithIcon>
      <p className="progress_tracker__upnext__reason__title">
        In order to continue the delivery of high quality care, annual lab work is required by your Maximus doctor .
        Please order new labs below or upload your own if you already have them.
      </p>
      <Formik initialValues={{ lab_type: '' }} onSubmit={() => {}}>
        {({ setFieldValue, values }) => (
          <Form>
            <div className="price_row">
              <Radio
                className="radio no-borders mb8"
                onChange={() => setFieldValue('lab_type', LabKitTypes.AtHomeLabKit)}
                checked={values.lab_type === LabKitTypes.AtHomeLabKit}
                label={<div className="radio-label">At-Home Lab Kit</div>}
              />
              <PriceFormatter price={productPriceCalculator.KING_LAB_COST} />
            </div>
            <div className="price_row mb16">
              <Radio
                className="radio no-borders mb8"
                onChange={() => setFieldValue('lab_type', LabKitTypes.WalkIn)}
                checked={values.lab_type === LabKitTypes.WalkIn}
                label={<div className="radio-label">Walk-In (Quest Labs)</div>}
              />
              <PriceFormatter price={productPriceCalculator.KING_WALK_IN_LAB_COST} />
            </div>
            <SecondaryButton
              onClick={() => dispatch(push(Routes.AnnualLabCheckout.replace(':lab_type', values.lab_type)))}
              text="Order Labs"
            />
            <h2 className="resource__heading align-center">Already have Labs?</h2>
            <p className="progress_tracker__upnext__reason__title">
              If you have lab work completed within the past 6 months and meet the clinical requirements, you may use
              your own lab results instead.
            </p>
            <SecondaryButton onClick={() => dispatch(push(Routes.UploadOwnLabs))} text="Use Own Labs" />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AnnualLabOrder;
